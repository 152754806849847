import React from 'react'
import Button from "../../components/Button";
// import Table from "../../components/Table";
import payload from "../../assets/payloadsvg.svg"

const CGateUssdDocumentationScreen = () => {
    return (
        <div>
            <div className="developer-wrapper">
                <div className="developer-wrapper-overlay">
                    <div className="developer-wrapper-overlay-inner">
                        <h3>C’Gate USSD Documentation</h3>
                        <p className="mt-2 small">This feature is the first in the industry. It will enable customers
                            to use their USSD PIN to authorize POS or WEB transaction.
                        </p>
                        <br /><br />
                        {/*<div style={{display:'flex'}} >*/}
                        {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                        {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="developer-wrapper-mobile">
                <div className="developer-wrapper-overlay">
                    <div className="developer-wrapper-overlay-inner">
                        <h3>C’Gate USSD Documentation</h3>
                        <p className="small">This feature is the first in the industry. It will enable customers
                            to use their USSD PIN to authorize POS or WEB transaction.</p>
                        {/*<div style={{display:'flex', flexDirection:"column",justifyContent:"center",gap:"20px"}}>*/}
                        {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                        {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <div className="doc-div-parent-doc">
                <div className="docNav">
                    <h3>Documentation</h3>
                    <ul>
                        <a href={'/developer/docs/cgateussd'}> <li>C’Gate USSD</li></a>
                        <a href={'/developer/docs/pay-with-bank-transfer'}> <li>Pay With Bank Transfer</li></a>
                        <a href={'/developer/docs/payment-gateway'}> <li>CoralPay Payment Gateway</li></a>
                        <a href={'/developer/docs/vas'}> <li>CoralPay VAS</li></a>
                        <a href={'/developer/docs/card-api'}> <li>CoralPay Card APIs</li></a>
                    </ul>
                </div>
                <div className="doc-div-doc">
                    <h3>PURPOSE OF THIS DOCUMENT</h3>
                    <p>This document is a Technical Design and integration document of the CGate Payment
                        Gateway Interface. It provides guidance and template material which is intended to
                        assist the relevant stakeholders to easily integrate to the CGATE Payment Interface
                        for the processing of POS/WEB transaction using USSD Password.
                    </p>
                </div>
                <div className="doc-div-doc">
                    <h3>BASIS OF THIS DOCUMENT</h3>
                    <p>The CGate InstantPay Gateway is a secure interface designed by CoralPay for
                        payment processing and has the capabilities to do the following: -.
                    </p>
                </div>

                <div className="doc-div-doc">
                    <table>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>USSD ON POS/WEB</td>
                            <td>This feature is the first in the industry. It will enable customers <br />
                            to use their USSD Pin to  POS or WEB transaction</td>
                        </tr>
                    </table>
                </div>
                <div className="doc-div-doc">
                    <h3>USSD ON POS</h3>
                    <p>POS terminal profile for this scheme will be able to authorize customers’ transaction
                        using their USSD PIN. </p>
                    <p>User Scenario for USSD POS/WEB Transaction</p>
                    <p>Pre-Condition</p>
                    <div>
                        <p>1.0 Customer has finished purchasing items from a merchant Store or customer has
                            finished adding items to the cart on the website and wants to make payment
                        </p>
                        <p>2.0 Customer has a USSD Profile with his bank.</p>
                    </div>
                    <p>Post Condition</p>
                    <p>Customers USSD Profile is debited and the merchant POS/WEB credited for thetransaction</p>
                    <p>Main Scenario</p>

                    <p>1.0 Customer chooses to PAY with USSD on the POS or Website</p>
                    <p>2.0 Customer chooses USSD as mode of payment, for web payment, the website
                        computes the amount to pay base on his cart items</p>
                    <p>3.0 POS/Website send request to CGate to initiate a USSD session, CGate
                        generate a reference number that is displayed on the POS or Website</p>
                    <p>4.0 Customer initiate a USSD session by dialling the short code dedicated by his
                        institution for POS/WEB USSD payment</p>
                    <p>5.0 USSD session present the screen for customer to enter the reference number
                        generated by the POS/WEB</p>
                    <p>6.0 Customer enter the reference number and chooses to continue</p>
                    <p>7.0 USSD Provider queries CGate Interface passing the reference number to
                        retrieve the amount and display the amount on the mobile screen</p>
                    <p>8.0 Customer confirm the transaction on the mobile USSD session with his USSD
                        PIN</p>
                    <p>9.0 USSD Provider send the transaction to CGATE for posting</p>
                    <p>10.0 CGATE interface send a direct debit transaction to the institution using the
                    reference number, then the USSD Provider will debit the customer with the
                        amount and authorized the transaction</p>
                    <p>11.0 CGATE update the transaction as successful</p>
                    <p>12.0 POS/Website queries the CGate interface to confirm the status of the
                        transaction*</p>
                    <p>13.0 POS/Website generate a receipt for the customer.</p>



                </div>
                <div className="doc-div-doc">
                    <h3 style={{width:'100%',marginBottom:'30px'}}>Generate a reference from CGate InstantPay API for the POS/WEB USSD Transaction</h3>

                    <img src={payload} width="906" height="871"  alt="#"/>
                </div>

                <div className="doc-div-doc">
                    <table style={{width:'910px'}}>
                        <tr>
                            <td >Request Data</td>
                            <td >HEX(PGP(JSON Object)</td>
                            <td ></td>
                        </tr>
                        <tr ></tr>
                        <tr >
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr >
                            <td >username</td>
                            <td >This is the username assigned to the channel
                                user for authentication</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >password</td>
                            <td >This is the password assigned to the channel
                                user for authentication</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >terminalId</td>
                            <td >This is the terminal that the customer wants
                                to make payment from. Please note for WEB
                                Merchant, this terminal will be created for
                                the merchant and sent to him.</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >amount</td>
                            <td >This is the amount will pay for the item
                                purchased</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >merchantId</td>
                            <td >This is the merchant Id of the terminal the
                                customer wants to make payment for. Please
                                note for WEB Merchant, this merchant Id
                                will be created and will be sent to him.</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >TraceID</td>
                            <td >This is used as a spare property</td>
                            <td >Optional</td>
                        </tr>
                        <tr >
                            <td >Channel</td>
                            <td >This is to determine the source of the
                                transaction. (WEB, POS, USSD)</td>
                            <td >Optional</td>
                        </tr>
                        <tr >
                            <td >SubMerchant Name</td>
                            <td >This is the name of the aggregators
                                merchant.</td>
                            <td >Optional</td>
                        </tr>
                        <tr >
                            <td >TransactionType</td>
                            <td >TThis is to determine the transaction type, the
                                default value is 0.
                                <br/>
                                0 = purchase, 30=Cashout, 35=Cashin, 50=
                                Bill Payment</td>
                            <td >Optional</td>
                        </tr>
                    </table>
                </div>
                <div className="doc-div-doc">
                    <h3>Response from the CGATE InstantPay Interface</h3>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td >Content-Type</td>
                            <td >Text/plain</td>
                        </tr>
                        <tr >
                            <td >Response JSON Object</td>
                            <td >{`
                                { "ResponseHeader": {
                                "ResponseCode": "00",
                                "ResponseMessage": "Success"
                            }, `} <br /> {`
                                "ResponseDetails": {
                                "Reference": "1856",
                                "Amount": "2000.0",
                                "TransactionID": "19012301000000001357"
                                "TraceID": "09283474728" }`
                            }</td>
                        </tr>
                    </table>
                    <br /><br />

                    <table style={{width:'910px'}}>
                        <tr>
                            <td >Request Data</td>
                            <td >HEX(PGP(JSON Object)</td>
                        </tr>
                        <tr ></tr>
                        <tr >
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                        </tr>
                        <tr >
                            <td >responseCode</td>
                            <td >The response code of the request</td>
                        </tr>
                        <tr >
                            <td >reference</td>
                            <td >This is the reference to be used by
                                the customer for the POS/WEB
                                USSD payment</td>
                        </tr>
                        <tr >
                            <td >responseMessage</td>
                            <td >This is the description of the
                                response</td>
                        </tr>
                        <tr >
                            <td >TransactionId</td>
                            <td >This is the Transaction Identifier</td>
                        </tr>
                    </table>


                </div>

                <div className="doc-div-doc">
                    <h3>Transaction Status from the POS/WEB/APP</h3>

                    <p>This is a callBack endpoint where the status of the transaction will be pushed. This
                        will return the successful response when customer has made payment. CGATE
                        InstantPay API expires the reference in 5minutes. The specification below is
                        expected to be implemented and the endpoint sent for profiling on Cgate.</p>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td >Content-Type</td>
                            <td >Text/plain</td>
                        </tr>
                        <tr >
                            <td >Response JSON Object</td>
                            <td >
                                {`{`} <br />
                                {` "responseCode": "00", `} <br />
                                {`"responsemessage": "Success" `}<br />
                                {` "reference": "1466", `}<br />
                                 {`   "amount": 2000.00,`}<br />
                                  {`"terminalId": "1057P010",`}<br />
                                  {` "merchantId": "1057P0100000001",`}<br />
                                  {`  "retrievalReference": "19010811000000000967",`}<br />
                                  {`  "institutionCode": "777",`}<br />
                                   {` "shortName": "Sink Node",`}<br />
                                   {` "customer_mobile": "080",`}<br />
                                  { ` "SubMerchantName": "Contoso Limited",`}<br />
                                  { ` "TransactionID": "19010811000000000967",`}<br />
                                  { ` "UserID": "66141919074003",`}<br />
                                  { ` "TraceID": "xyz123abc" ` } <br />
                                {` } `}
                            </td>
                        </tr>
                    </table>
                    <br /><br />
                    <table style={{width:'910px'}}>
                        <tr>
                            <td >Request Data</td>
                            <td >HEX(PGP(JSON Object)</td>
                            <td ></td>
                        </tr>
                        <tr ></tr>
                        <tr >
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr >
                            <td >reference</td>
                            <td >The reference generated by
                                the by the first request and
                                displayed for the customer
                                to make payment.</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >responseCode</td>
                            <td >The response code of the
                                request</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >reference</td>
                            <td >This is the reference to be
                                used by the customer for
                                the POS/WEB USSD
                                payment</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >responseMessage</td>
                            <td >This is the description of
                                the response</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >Shortcode</td>
                            <td >Short Code dialled by the
                                customer</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >customer_mobile</td>
                            <td >The Mobile Number of the
                                customer</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >terminalId</td>
                            <td >This is the terminal that the
                                customer wants to make
                                payment from. Please note
                                for WEB Merchant, this
                                terminal will be created for
                                the merchant and sent to
                                him.</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >TransactionID</td>
                            <td >This is the transaction
                                Identifier</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >SubMerchantName</td>
                            <td >This is the name of the
                                aggregators merchant.</td>
                            <td ></td>
                        </tr>
                        <tr >
                            <td >UserID</td>
                            <td >This is the unique ID for
                                the customer dialling.</td>
                            <td ></td>
                        </tr>
                    </table>

                </div>

                <div className="doc-div-doc">
                    <h3>Transaction Query from the POS/WEB/APP with transaction number</h3>
                    <p>The transaction Id will be used by the POS/WEB/APP to Query the status of the
                        transaction. This will return the successful response when customer has made
                        payment. This transaction confirmation endpoint can be used to query for
                        transactions that have exceeded 5 minutes from the time the reference code was
                        generated. Please note that you will be unable to query for transactions on the Cgate
                        InstantPay Interface using this endpoint after 3 months from when the reference
                        code was generated.</p>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td >url</td>
                            <td >https://testdev.coralpay.com/cgateproxy/api/statusquery</td>
                        </tr>
                        <tr>
                            <td >Verb</td>
                            <td >POST</td>
                        </tr>
                        <tr>
                            <td >Content-Type</td>
                            <td >Text/plain</td>
                        </tr>
                        <tr >
                            <td >Response JSON Object</td>
                            <td >
                                {`{`} <br />
                                {` "RequestHeader": `} {`{`} <br />
                                    {`"Username": "Citi_Serve",`} <br />
                                    {`"Password": "PosP@ssw3824652" `}<br />
                                {`}`} <br />
                                {` "RequestDetails": `} {`{`} <br />
                                    {`"TerminalId": "1057P010",`} <br />
                                    {`"Amount": 2000.0, `}<br />
                                    {`"MerchantId": "1057P0100000001", `}<br />
                                    {`"TransactionID":"19012307000000001388" `}<br />
                                {`}`} <br />
                                {`}`}
                            </td>
                        </tr>

                        <tr>
                            <td >Request Data</td>
                            <td >HEX(PGP(JSON Object)</td>
                            <td ></td>
                        </tr>
                        <tr ></tr>
                        <tr >
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr >
                            <td >TransactionId</td>
                            <td >This is the transaction identifier</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >username</td>
                            <td >This is the username assigned to the channel
                                user for authentication</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >password</td>
                            <td >This is the password assigned to the channel
                                user for authentication</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >terminalId</td>
                            <td >This is the terminal that the customer wants
                                to make payment from. Please note for WEB
                                Merchant, this terminal will be created for
                                the merchant and sent to him.</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >amount</td>
                            <td >This is the amount will pay for the item
                                purchased</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >merchantId</td>
                            <td >This is the merchant Id of the terminal the
                                customer wants to make payment for. Please
                                note for WEB Merchant, this merchant Id
                                will be created and will be sent to him.</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >TraceID</td>
                            <td >This is used as a spare property</td>
                            <td >Optional</td>
                        </tr>
                        <tr >
                            <td >Channel</td>
                            <td >This is to determine the source of the
                                transaction. (WEB, POS, USSD)</td>
                            <td >Optional</td>
                        </tr>
                        <tr >
                            <td >SubMerchant Name</td>
                            <td >This is the name of the aggregators
                                merchant.</td>
                            <td >Optional</td>
                        </tr>
                    </table>

                </div>

                <div className="doc-div-doc">
                    <table style={{width:'910px'}}>
                        <tr>
                            <td >Verb</td>
                            <td >Post</td>
                        </tr> <tr>
                            <td >Content-Type</td>
                            <td >Text/plain</td>
                        </tr>
                        <tr >
                            <td >Response JSON Object</td>
                            <td >
                                {`{`} <br />
                                {` "responseCode": "00", `} <br />
                                {`"responsemessage": "Success" `}<br />
                                {` "reference": "1466", `}<br />
                                {`   "amount": 2000.00,`}<br />
                                {`"terminalId": "1057P010",`}<br />
                                {` "merchantId": "1057P0100000001",`}<br />
                                {`  "retrievalReference": "19010811000000000967",`}<br />
                                {`  "institutionCode": "777",`}<br />
                                {` "shortName": "Sink Node",`}<br />
                                {` "customer_mobile": "080",`}<br />
                                { ` "SubMerchantName": "Contoso Limited",`}<br />
                                { ` "TransactionID": "19010811000000000967",`}<br />
                                { ` "UserID": "66141919074003",`}<br />
                                { ` "TraceID": "xyz123abc" ` } <br />
                                {` } `}
                            </td>
                        </tr>
                            <tr>
                                <td >Request Data</td>
                                <td >HEX(PGP(JSON Object)</td>
                            </tr>
                            <tr ></tr>
                            <tr >
                                <td></td>
                                <th style={{textAlign:'center'}}>Field Description</th>
                            </tr>
                            <tr >
                                <td >responseCode</td>
                                <td >The response code of the request</td>
                            </tr>
                            <tr >
                                <td >reference</td>
                                <td >This is the reference to be used by
                                    the customer for the POS/WEB
                                    USSD payment</td>
                            </tr>
                            <tr >
                                <td >responseMessage</td>
                                <td >This is the description of the
                                    response</td>
                            </tr>
                            <tr >
                                <td >TransactionId</td>
                                <td >This is the Transaction Identifier</td>
                            </tr>

                    </table>

                </div>


                <div className="doc-div-doc">
                    <h3>Refund from the POS/WEB/APP with transaction number</h3>

                    <p>This is the interface used to Refund previous successful and settled POS/WEB/APP
                        transactions, you’ll be required to supply your credentials and the transaction
                        identifiers. Please note that only transactions within 180days can be refunded.</p>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td >url</td>
                            <td >https://testdev.coralpay.com/cgateproxy/api/refund</td>
                        </tr>
                        <tr>
                            <td >Verb</td>
                            <td >POST</td>
                        </tr>
                        <tr>
                            <td >Content-Type</td>
                            <td >Text/plain</td>
                        </tr>
                        <tr >
                            <td >Response JSON Object</td>
                            <td >
                                {`{`} <br />
                                {` "RequestHeader": `} {`{`} <br />
                                {`"Username": "City_Loans",`} <br />
                                {`"Password": "Kos$ssw3824652" `}<br />
                                {`}`} <br />
                                {` "ReversalDetails": `} {`{`} <br />
                                {`"TerminalId": "1057P010",`} <br />
                                {`"Amount": 2000.0, `}<br />
                                {`"MerchantId": "1057P0100000001", `}<br />
                                {`"TransactionID":"19012307000000001388" `}<br />
                                {`}`} <br />
                                {`}`}
                            </td>
                        </tr>

                        <tr>
                            <td >Request Data</td>
                            <td >HEX(PGP(JSON Object)</td>
                            <td ></td>
                        </tr>
                        <tr ></tr>
                        <tr >
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr >
                            <td >TransactionId</td>
                            <td >This is the transaction identifier</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >username</td>
                            <td >This is the username assigned to the channel
                                user for authentication</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >password</td>
                            <td >This is the password assigned to the channel
                                user for authentication</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >terminalId</td>
                            <td >This is the terminal that the customer wants
                                to make payment from. Please note for WEB
                                Merchant, this terminal will be created for
                                the merchant and sent to him.</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >amount</td>
                            <td >This is the amount will pay for the item
                                purchased</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >merchantId</td>
                            <td >This is the merchant Id of the terminal the
                                customer wants to make payment for. Please
                                note for WEB Merchant, this merchant Id
                                will be created and will be sent to him.</td>
                            <td >Mandatory</td>
                        </tr>
                        <tr >
                            <td >TraceID</td>
                            <td >This is used as a spare property</td>
                            <td >Optional</td>
                        </tr>
                        <tr >
                            <td >Channel</td>
                            <td >This is to determine the source of the
                                transaction. (WEB, POS, USSD)</td>
                            <td >Optional</td>
                        </tr>
                        <tr>
                            <td>SubMerchant Name</td>
                            <td>This is the name of the aggregators
                                merchant.</td>
                            <td>Optional</td>
                        </tr>
                    </table>


                    <br />

                    <table style={{width:'910px'}}>
                        <tr>
                            <th>Response Code</th>
                            <th>Description</th>
                        </tr>

                        <tr>
                            <td>00</td>
                            <td>Successful approval/completion</td>
                        </tr><tr>
                            <td>03</td>
                            <td>Invalid service provider or Institution not

                                properly setup</td>
                        </tr><tr>
                            <td>05</td>
                            <td>Do not honor</td>
                        </tr><tr>
                            <td>06</td>
                            <td>General Error or Fatal Error</td>
                        </tr><tr>
                            <td>12</td>
                            <td>Invalid transaction</td>
                        </tr><tr>
                            <td>13</td>
                            <td>Invalid Amount</td>
                        </tr><tr>
                            <td>14</td>
                            <td>Credit account number is invalid</td>
                        </tr><tr>
                            <td>15</td>
                            <td>Forwarding Institution has not been setup</td>
                        </tr><tr>
                            <td>16</td>
                            <td>Insufficient Fund</td>
                        </tr><tr>
                            <td>25</td>
                            <td>Unable to locate the account or transaction</td>
                        </tr><tr>
                            <td>30</td>
                            <td>Format Error. System cannot decrypt
                                message</td>
                        </tr><tr>
                            <td>51</td>
                            <td>Insufficient funds</td>
                        </tr><tr>
                            <td>52</td>
                            <td>Account does not exist</td>
                        </tr><tr>
                            <td>53</td>
                            <td>Account number does not exist</td>
                        </tr><tr>
                            <td>55</td>
                            <td>Incorrect PIN(USSD or Mobile or One time
                                token)</td>
                        </tr><tr>
                            <td>57</td>
                            <td>Transaction type is not permitted to account</td>
                        </tr><tr>
                            <td>58</td>
                            <td>This terminal has not been setup for this

                                transaction</td>
                        </tr><tr>
                            <td>61</td>
                            <td>Amount Limit exceeded</td>
                        </tr><tr>
                            <td>63</td>
                            <td>Security Violation</td>
                        </tr><tr>
                            <td>78</td>
                            <td>This account has been blacklisted</td>
                        </tr>
                        <tr>
                            <td>92</td>
                            <td>Destination transaction route not found</td>
                        </tr>
                        <tr>
                            <td>96</td>
                            <td>System Malfunction</td>
                        </tr>
                    </table>

                    <p><b>The Cgate Response codes are based on ISO 8583 financial transaction codes. You
                        can find the full list of the codes and their meaning here:</b><br />
                        <a href="https://en.wikipedia.org/wiki/ISO_8583">https://en.wikipedia.org/wiki/ISO_8583</a></p>


                </div>

            </div>

            <div>
                <div></div>
                <div></div>
            </div>


        </div>
    )
}

export default CGateUssdDocumentationScreen