import React from 'react'
import "../../App.css"
import Button from "../../components/Button";
import Card3 from "../../components/Card3";
import padd1 from "../../assets/Paddi1.png"
import Api from "../../assets/API1.png"
import circle_dot2 from "../../assets/circle_dot2.svg";



const DeveloperHomeScreen = () => {
    return (
        <div>
            <img src={circle_dot2} className="circle_dot2" alt="#" />
            <div className="developer-wrapper" style={{backgroundImage:`url(${padd1})`}}>
                    <div className="developer-wrapper-overlay">
                        <div className="developer-wrapper-overlay-inner">
                            <p className="mb-1 small">CORALPAY FOR DEVELOPERS</p>
                             <h3>A Complete API <br /> Suite To Build Seamless <span style={{color:'#DCD35D'}}> Payment Infrastructure</span></h3>
                            <p className="mt-2 small">EXPLORE OUR VARIOUS APIS</p>
                            <br /><br />
                            {/*<div style={{display:'flex'}} >*/}
                            {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                            {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            <div className="developer-wrapper-mobile">
                <div className="developer-wrapper-overlay">
                    <div className="developer-wrapper-overlay-inner">
                        <p className="mb-1 small">CORALPAY FOR DEVELOPERS</p>
                        <h3>A Complete API <br /> Suite To Build Seamless <span style={{color:'#DCD35D'}}> Payment Infrastructure</span></h3>
                        <p className="mt-2 small">EXPLORE OUR VARIOUS APIS</p>
                        {/*<div style={{display:'flex', flexDirection:"column",justifyContent:"center",gap:"20px"}}>*/}
                        {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                        {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div>
                <div className="developer-wrapper" style={{backgroundImage:`url(${Api})`}}>
                        <div className="developer-wrapper-overlay2">
                            <div className="developer-wrapper-overlay-inner">
                                <h3 style={{color:"#7B3F63"}}>Intro to Usage </h3>
                                <p className="mt-2">This page provides a collection of API specifications. We are committed to enhancing open API implementations,
                                    and giving the needed support to all licensed entities.</p>
                            </div>
                        </div>
                    </div>
                <div className="developer-wrapper-mobile2" style={{backgroundImage:`url(${Api})`}}>
                        <div className="developer-wrapper-overlay2">
                            <div className="developer-wrapper-overlay-inner2">
                                <h3 style={{color:"#7B3F63"}}>Intro to Usage </h3>
                                <p>This page provides a collection of API specifications. We are committed to enhancing open API implementations,
                                    and giving the needed support to all licensed entities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="section-card">
                <div className="section-card-inner">
                    <Card3 />
                </div>
            </div>
        </div>

    )
}

export default DeveloperHomeScreen