import React from "react";
import "../App.css"
import contact from "../assets/contact-woman.png";
import Button from "../components/Button";
import contactimg from "../assets/contactimg.png"
import circle_dot2 from "../assets/circle_dot2.svg";
import {Formik, Form} from 'formik'
import axios from "axios";
const ContactScreen = () => {
    let contactRequest={
        To:'customersupport@coralpay.com',
        ToName:'Customer support',
        From:null,
        FromName:null,
        Subject:null,
        Body:null,
    }

    function doSubmit(values){
        if(isChecked){
            setLoading(true)
            axios({
                method: 'post',
                url: 'https://testdev.coralpay.com/CoralMailService/api/SendEmail',
                data:values
            }).then(response =>{
                setLoading(false)
               let responseData = response.data
                if(responseData.responseCode === "00"){
                    alert(responseData.responseMessage)
                }else{
                    alert(responseData.responseMessage)
                }
            }).catch(e =>{
                setLoading(false)
                alert(e.message)
            });}else{
            alert('You must accept to CoralPay Terms and Condition before proceeding')
        }
    }

     const [loading, setLoading] = React.useState(false)
     const [isChecked, setIsChecked] = React.useState(false)

    return(
        <div>
            <img src={circle_dot2} className="circle_dot2" alt="#" />
            <div className="blog-header" style={{backgroundImage:`url(${contact})`}}>
                <div className="blog-wrapper-overlay">
                    <h3>We Are Glad to  <span style={{color:'#F7EF8A'}}>Hear From You</span></h3>
                </div>
            </div>
            <br /><br /><br /><br />
            <div>
                <h3 className="recentpost">Leave Us A Message</h3>
                <Formik initialValues={contactRequest} onSubmit={(values) => doSubmit(values)}>
                    {(props) => (
                        <Form className="form-contact">
                            <div className="content-message">
                                <textarea
                                    className="subscribe-input-message"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.Body}
                                    name={'Body'}
                                />
                            </div>
                            <div className="contact-form">
                                <div className="contact-form-input">
                                    <input type="text"
                                           className="subscribe-input"
                                           placeholder="Enter the Subject"
                                           required
                                           onChange={props.handleChange}
                                           onBlur={props.handleBlur}
                                           value={props.values.Subject}
                                           name={'Subject'}
                                    />
                                    <input
                                        type="text"
                                        className="subscribe-input"
                                        placeholder="Enter Your Name"
                                        required
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.FromName}
                                        name={'FromName'}
                                    />
                                    <input
                                        type="email"
                                        className="subscribe-input"
                                        placeholder="Enter Your Email Address"
                                        required
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.From}
                                        name={'From'}
                                    />
                                    <div className="text-center" style={{width:'100%'}}>
                                        <p className="contact-content" style={{width:'100%'}}><input type='checkbox' onChange={() => setIsChecked(!isChecked)}/> By checking this, you agree to our <span style={{color:'#7D3E64'}}><a href="/terms-conditions/"> Terms</a></span> and <span style={{color:'#7D3E64'}}><a href="/privacy-statement/"> Privacy Policy</a></span></p>
                                        <Button message="Submit" bgColor='#7B3F63' loading={loading} />
                                    </div>

                                </div>
                                <div className="contact-us-woman">
                                    <img src={contactimg} alt="hello" />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                </div>
            {/*<div className="contact-us-woman">*/}
            {/*    <img src={contactimg} alt="hello" />*/}
            {/*</div>*/}
        </div>

    )
}

export default ContactScreen