import devICon1 from "../src/assets/icons/dev-icon-1.svg"
import devICon2 from "../src/assets/icons/dev-icon-2.svg"
import vector2 from "../src/assets/icons/Vector2.svg"

const payload = [
    {
        topIcon:devICon1,
        title:"C’Gate USSD",
        content:"This feature is the first in the industry. It will enable customers \n" +
            "to use their USSD PIN to authorize POS or WEB transaction.",
        bottomIcon:vector2,
        footerContent:"See Documentation",
        link:'/developer/docs/cgateussd'
    },
    {
        topIcon:devICon2,
        title:"Pay With Bank Transfer",
        content:"Pay with Bank Transfer is a secure way to make payment with account number, which ensures that best practices are observed while making payments through bank transfers.",
        bottomIcon:vector2,
        footerContent:"See Documentation",
        link:'/developer/docs/pay-with-bank-transfer'

    },
    {
        topIcon:devICon2,
        title:"CoralPay Payment Gateway",
        content:"Our Payment Gateway aggregates all our payment solutions in one place, enabling customers to have various payment options at merchant’s checkout point",
        bottomIcon:vector2,
        footerContent:"See Documentation",
        link:'/developer/docs/payment-gateway'

    },
    {
        topIcon:devICon1,
        title:"CoralPay VAS",
        content:"The CoralPay VAS is a secure interface for vending value for different Billers",
        bottomIcon:vector2,
        footerContent:"See Documentation",
        link:'/developer/docs/vas'

    },
    {
        topIcon:devICon2,
        title:"CoralPay Card APIs",
        content:"The OctoPlus Payment Gateway is a secure interface designed by CoralPay for card" +
        "processing and has the capabilities to process payment on behalf of merchant who " +
        "wants to accept card from their interface",
        bottomIcon:vector2,
        footerContent:"See Documentation",
        link:'/developer/docs/card-api'

    },
]

export default payload