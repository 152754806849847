import React from "react";
import payload from "../ProductAndServicePayload";

const Card1 = () => {
  const card1component = payload.map((i, index) => {
    return (
      <div>
        <div className="card1">
          <div
            className="card-img"
            style={{
              borderColor: i.imgBorderColor,
              backgroundImage: `url(${i.imgIcon})`,
            }}
          ></div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "250px",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <div className="pl-5">
              <h3 className="home-card-h3" style={{ color: i.titleColor }}>
                {i.topTitle}
              </h3>
              <br />
              <p className="home-card-p">{i.content}</p>
            </div>
            <div
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "flex-end",
              }}
            >
              <a href={i.link}>
                {" "}
                <img src={i.bottomTitle} alt="back" />
              </a>
              {/* <span className="ml-3">{i.bottomTitle}</span> */}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return [card1component];
};

export default Card1;
