import React from "react"
import Button from "../../components/Button";
import {NavLink} from "react-router-dom";


const CoralpayCardApiDocumentation = () => {
    return(
        <div>
            <div className="developer-wrapper">
                <div className="developer-wrapper-overlay">
                    <div className="developer-wrapper-overlay-inner">
                        <h3>Card API Documentation</h3>
                        <p className="mt-2 small">This document is a Technical Design and integration document of the OctoPlus
                            Custompay API Interface. It provides guidance and template material which is
                            intended to assist the relevant stakeholders to easily integrate to the OctoPlus
                            CustomPay Interface for the processing Card Transaction
                        </p>
                        <br /><br />
                        {/*<div style={{display:'flex'}} >*/}
                        {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                        {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <div className="doc-div-parent-doc">
                <div className="docNav">
                    <h3>Documentation</h3>
                    <ul>
                        <a href={'/developer/docs/cgateussd'}> <li>C’Gate USSD</li></a>
                        <a href={'/developer/docs/pay-with-bank-transfer'}> <li>Pay With Bank Transfer</li></a>
                        <a href={'/developer/docs/payment-gateway'}> <li>CoralPay Payment Gateway</li></a>
                        <a href={'/developer/docs/vas'}> <li>CoralPay VAS</li></a>
                        <a href={'/developer/docs/card-api'}> <li>CoralPay Card APIs</li></a>
                    </ul>
                </div>

                <div className="doc-div-doc">
                    <h3>BASIS OF THIS DOCUMENT</h3>

                    <p>The OctoPlus Payment Gateway is a secure interface designed by CoralPay for card
                        processing and has the capabilities to process payment on behalf of merchant who
                        wants to accept card from their interface: -.</p>


                    <h3>OCTOPLUS MERCHANT PAY USE CASE</h3>
                    <p>User Scenario for accepting card transaction on the OctoPlus merchant pay</p>
                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'order',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>Merchant Information has been profiled on OctoPlus Web Pay Gateway</li>
                        <li>Merchant callback url for successful and failed payment is called if available</li>
                        <li>Customer has a valid MasterCard, Visa or Verve</li>
                    </ul>

                    <span>Post Condition</span>
                    <ul style={{listStyle:'order',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>Customers card is debited and transaction is redirected to merchant callback url</li>
                    </ul>

                    <span>Use Case</span>
                    <ul style={{listStyle:'order',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>Customer browses merchant portal and add product or services to cart</li>
                        <li>Customer checkout payment</li>
                        <li>Merchant call the request payment API</li>
                        <li>Merchant Redirect to the send OctoPlus Payment Gate</li>
                        <li>Customer enters his card information,CVV and expiry date</li>
                        <li>OctoPlus Gateway redirect to 3D Secure Page</li>
                        <li>Customer enters his detail on the 3D Secure page</li>
                        <li>OctoPlus Service verify customer card and performs 3D secure. Customer may be
                            prompted to enrol for 3Dsecure/VbV by the issuer if not currently enrolled</li>
                        <li>Upon successful 3D secure, OctoPlus gateway debit customer and notifies
                            merchant callback url if availabel.</li>
                    </ul>

                    <br />

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>url</td>
                            <td>https://cnptest.coralpay.com:9443/octoweb/cnp/requestPayment</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>Post</td>
                        </tr><tr>
                        <td>Content Type</td>
                        <td>Json</td>
                    </tr><tr>
                        <td>JSON Object</td>
                        <td>{`{ `}<br />
                            {`"merchantId": "2058DE016809207",`}<br />
                            {`"merchantRef": "546255421116667",`}<br />
                            {`"amount": 100,`}<br />
                            {`"callBackUrlSuccess":"http://25.58.102.5/callback/successful",`}<br />
                            {`"callBackUrlFailed":"http://25.58.102.5/callback/failed",`}<br />
                            {`"isTokenize":0`}<br />
                            {`}`}</td>
                    </tr><tr>
                        <td>Request Data</td>
                        <td> Authorization Basic clientSecretKey</td>
                    </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>{`{ `}<br />
                                {`"responseCode": "0000",`}<br />
                                {`"responsemessage": "Successful",`}<br />
                                {`"navigatingUrl":
                                    "https://cnptest.coralpay.com:9443//octoweb/payment/
                                    quickpay/eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJFMTU2
                                    RjYzNjA3OTFGMDdDOUIxNUI1QzZFM0M0MDU0R
                                    EE0MUQxQjJGRTZCMDA1NzM3RUEyRkQ1ODI1Qk
                                    QyOUJEIiwiaXNzIjoiT2N0b3BsdXMiLCJtaWQiOiI1M
                                    iIsInR5cCI6ImFjY2VzcyIsImV4cCI6MTYzMjE2MzA3M
                                    CwiaWF0IjoxNjMyMTUyMjcwLCJqdGkiOiJmZjJiMTY
                                    3Yy02YWYzLTQzZWMtYjYwOC1kN2Y1YjRmZWEyNT
                                    
                                    cifQ.Ltf_wBV_dpImwx6A2Phuzm3R7ImX-
                                    vbYL7wzejmdJv4"`}<br />
                                {`}`}</td>
                        </tr>
                    </table>
                    <br />
                    <p style={{textAlign:'center',fontWeight:'800',textDecoration:'underline'}}>Request</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>merchantId</td>
                            <td>This is a unique merchantId that will be sent to
                                the merchant</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>merchantRef</td>
                            <td>This is a unique merchant ref generate by
                                merchant to identify the transaction.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>The transaction amount.</td>
                            <td>Mandatory</td>
                        </tr><tr>
                        <td>callBackUrlSuccess</td>
                        <td>This is the url that will be called after a successful
                            payment</td>
                        <td>Mandatory</td>
                    </tr><tr>
                        <td>callBackUrlFailed</td>
                        <td>This is the url that will be called after a successful
                            payment</td>
                    </tr><tr>
                        <td>isTokenize</td>
                        <td>This is to indicate if card should be tokenize</td>
                        <td>Optional</td>
                    </tr>
                    </table>
                    <br />

                    <p style={{textAlign:'center',fontWeight:'800',textDecoration:'underline'}}>Response</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code for the request 0000 or
                                00 is successful</td>
                            <td>Mandatory</td>

                        </tr>
                        <tr>
                            <td>responsemessage</td>
                            <td>This is the narration of the response</td>
                            <td>Mandatory</td>

                        </tr>
                        <tr>
                            <td>navigatingUrl</td>
                            <td>For a successful transaction, The merchant will
                                redirect to the url for customer to make payment</td>
                            <td>Mandatory</td>

                        </tr>
                    </table>

                    <p style={{fontWeight:'800'}}>Upon redirect to the payment navigatingUrl, the payment page is displayed for customer to
                        enter his card detail</p>

                    <h3>WEB HOOK OR CALLBACK URL:</h3>
                    <p>The merchant can also provide a web hook or callback url. The OctoPlus merchant
                        pay will call the web hook url upon completion of transaction. The OctoPlus
                        merchant post this information to the web hook or callback url</p>

                    <table style={{width:'910px'}}>
                    <tr>
                        <td ></td>
                        <th style={{textAlign:'center'}}>Field Description (Merchant Callback)</th>
                        <td ></td>
                    </tr>
                    <tr ></tr>
                    <tr >
                        <td></td>
                        <th style={{textAlign:'center'}}>Field Description</th>
                        <td></td>
                    </tr>
                    <tr >
                        <td >responseCode</td>
                        <td >The response code of the request</td>
                        <td >Mandatory</td>
                    </tr>
                    <tr >
                        <td>token</td>
                        <td >The tokenize request for performing
                            transaction</td>
                        <td >Optional: only
                            available if
                            Successful and if
                            tokenize is 1</td>
                    </tr>
                    <tr >
                        <td> transactionRef</td>
                        <td >The reference for the transaction</td>
                        <td >Optional</td>
                    </tr>
                    <tr >
                        <td >retrievalReferen
                            ce</td>
                        <td >The retrieval reference sent by the
                            issuer</td>
                        <td >Optional, available
                            if transaction is
                            successful</td>
                    </tr>
                        <tr >
                        <td >responseMsg</td>
                        <td >Response message</td>
                        <td ></td>
                    </tr>
                        <tr >
                        <td >tnxId</td>
                        <td >Transaction Id generated by octoplus
                            portal and this is value that will be
                            passed for refund or when quering for
                            the status of the transaction</td>
                        <td >Optional, available
                            if transaction is
                            successful</td>
                    </tr>
                    </table>
                    <br/>

                    <h3>Querying Transaction for WEB Transaction</h3>
                    <p>Merchant can re-query WEB transaction to get the status on the transaction and the
                        transaction amount approved by the switch</p>

                    <br />
                        <table style={{width:'910px'}}>
                            <tr>
                                <td>url</td>
                                <td>https://cnptest.coralpay.com:9443/octoweb/payment/ queryTransaction</td>
                            </tr>
                            <tr>
                                <td>Verb</td>
                                <td>Post</td>
                            </tr><tr>
                            <td>Content Type</td>
                            <td>Json</td>
                        </tr><tr>
                            <td>JSON Object</td>
                            <td>{`{ `}<br />
                                {`"merchantId":"123456789012345",`}<br />
                                {`"tnxId":"210000000014580620210222192017111132"`}<br />
                                {`}`}</td>
                        </tr><tr>
                            <td>Request Data</td>
                            <td> Authorization Basic clientSecretKey</td>
                        </tr>
                            <tr>
                                <td>Response Data</td>
                                <td>{`{ `}<br />
                                    {`"merchantId":"123456789012345",`}<br />
                                    {`"merchantRef":"7589645286780",`}<br />
                                    {`"transactionId":"9484775775MDC",`}<br />
                                    {`"amount":"30.50",`}<br />
                                    {`"retrievalRef":"045287651157",`}<br />
                                    {`"responseCode":"0000",`}<br />
                                    {`"responseMsg":"Approved"`}<br />
                                    {`}`}</td>
                            </tr><tr>
                                <td></td>
                                <td>Please note the responseMsg can either be Approved,
                                    Reversed, Refund. A transaction is approved when
                                    responseCode is either 0000 or 0002 and responseMsg is
                                    not (Refund, Reversed)</td>
                            </tr>
                        </table>
                    <br />
                    <h3>Refund WEB Transaction</h3>
                    <p>This is the ability for web merchant to refund a successful transaction when there is
                        a dispute</p>

                    <br />
                        <table style={{width:'910px'}}>
                            <tr>
                                <td>url</td>
                                <td>https://cnptest.coralpay.com:9443/octoweb/payment/
                                    refund</td>
                            </tr>
                            <tr>
                                <td>Verb</td>
                                <td>Post</td>
                            </tr><tr>
                            <td>Content Type</td>
                            <td>Json</td>
                        </tr><tr>
                            <td>JSON Object</td>
                            <td>{`{ `}<br />
                                {`"merchantId":"20580000000000X",`}<br />
                                {`"tnxId":"210000000014580620210222192017111132"`}<br />
                                {`"amount":30.50`}<br />
                                {`}`}</td>
                        </tr><tr>
                            <td>Request Data</td>
                            <td> Authorization Basic clientSecretKey</td>
                        </tr>
                            <tr>
                                <td>Response Data</td>
                                <td>{`{ `}<br />
                                    {`"merchantId":"123456789012345",`}<br />
                                    {`"merchantRef":"7589645286780",`}<br />
                                    {`"transactionId":"9484775775MDC",`}<br />
                                    {`"amount":"30.50",`}<br />
                                    {`"retrievalRef":"045287651157",`}<br />
                                    {`"responseCode":"0000",`}<br />
                                    {`"responseMsg":"Successful"`}<br />
                                    {`}`}</td>
                            </tr>
                        </table>


                </div>
            </div>

        </div>
    )
}

export default CoralpayCardApiDocumentation