import React from "react"

const PaywithBankTransferDocumentation = () => {
    return(
        <div>
            <div className="developer-wrapper">
                <div className="developer-wrapper-overlay">
                    <div className="developer-wrapper-overlay-inner">
                        <h3>CoralPay Direct Money Transfer</h3>
                        <p className="mt-2 small">This document is a Technical and integration document for CoralPay Direct Money Transfer. It
                            provides guidance and template material which is intended to assist the relevant stakeholders
                            to easily integrate to the Direct Money Transfer Service.</p>
                        <br /><br />
                        {/*<div style={{display:'flex'}} >*/}
                        {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                        {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="doc-div-parent-doc">
                <div className="docNav">
                    <h3>Documentation</h3>
                    <ul>
                        <a href={'/developer/docs/cgateussd'}> <li>C’Gate USSD</li></a>
                        <a href={'/developer/docs/pay-with-bank-transfer'}> <li>Pay With Bank Transfer</li></a>
                        <a href={'/developer/docs/payment-gateway'}> <li>CoralPay Payment Gateway</li></a>
                        <a href={'/developer/docs/vas'}> <li>CoralPay VAS</li></a>
                        <a href={'/developer/docs/card-api'}> <li>CoralPay Card APIs</li></a>
                    </ul>
                </div>
                <div className="doc-div-doc">
                <h3>Introduction</h3>

                <p>This document is a Technical and integration document for CoralPay Direct Money Transfer. It
                    provides guidance and template material which is intended to assist the relevant stakeholders
                    to easily integrate to the Direct Money Transfer Service. This service is meant for merchants /
                    institutions who intend to allow their customers/users fund their wallets and/or pay for
                    services through Direct Transfer from their bank accounts.</p>


                <h3>This service can be used for the following cases:</h3>
                    <ul style={{listStyle:'order',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>Wallet Funding</li>
                        <li>Payment for Services</li>
                    </ul>

                    <h3>There are two (2) types of Account Numbers that is applicable in this service:</h3>
                    <ul style={{listStyle:'order',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>Dynamic Account Number</li>
                        <li>Reserved/Static Account Number</li>
                    </ul>

                    <h3>Usage Flow:</h3>
                    <ul style={{listStyle:'order',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>Merchant sends an account number creation request to CoralPay to be given to a
                            customer who desires to fund his/her wallet or pay for services.</li>
                        <li>An account number is created and same returned to the merchant.</li>
                        <li>Merchant notifies customer of this Dynamic Account Number.</li>
                        <li>The customer makes a payment to the Dynamic Account Number.</li>
                        <li>CoralPay notifies merchant of payment made to the account number.</li>
                        <li>The Dynamic Account Number is deactivated.</li>
                    </ul>

                    <h3>API Specification</h3>
                    <h3>Generate Dynamic Account Number</h3>

                    <p>This operation is for merchants to generate a Dynamic Account Number for their customers
                        to make payments. These payments can either be to fund wallets or pay for services.</p>
                    <p>Below is the Specification for the API to submit the customer’s details.</p>
                    <p>BaseUrl: http://sandbox1.coralpay.com:8080/paywithtransfer/</p>

                <br />

                <table style={{width:'910px'}}>
                    <tr>
                        <td>Operation</td>
                        <td>Generate Dynamic Account Number</td>
                    </tr>
                    <tr>
                        <td>Endpoint</td>
                        <td>/moneytransfer/apis/dynamicAccount</td>
                    </tr>
                    <tr>
                    <td>Verb</td>
                    <td>Post</td>
                </tr><tr>
                    <td>Content Type</td>
                    <td>Json</td>
                </tr><tr>
                    <td>Authentication</td>
                    <td>Basic Username : Password</td>
                </tr><tr>
                    <td>Request Payload</td>
                    <td> {`{`} <br />
                        {` "RequestHeader": `} {`{`} <br />
                        {`"clientId": "a@a.com",`} <br />
                        {`"requestType": "Bank Transfer" `}<br />
                        {`},`} <br />
                        {`"customerName": "Shehu Chidi & Sons",`} <br />
                        {`"referenceNumber": "xsd4pe0t008952", `}<br />
                        {`"transactionAmount": "6754" `}<br />
                        {`}`}</td>
                </tr>
                    <tr>
                    <td>Response Payload</td>
                    <td>{`{`} <br />
                        {` "responseDetails": `} {`{`} <br />
                        {`"clientId": "a@a.com",`} <br />
                        {`"referenceNumber": "xsd4pe0t008952",`}<br />
                        {`"responseCode": "00", `}<br />
                        {`"responseMessage": "OPERATION SUCCESSFUL" `}<br />
                        {`},`} <br />
                        {`"accountName": "MERCHANT(Shehu Chidi & Sons)",`} <br />
                        {`"accountNumber": "9977573777", `}<br />
                        {`"operationReference": "merchant2201250331078455", `}<br />
                        {`"transactionAmount": "6754" `}<br />
                        {`}`}</td>
                </tr>
                </table>
                    <br />

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Description</td>
                            <td>Field</td>
                            <td>Description</td>
                            <td>Optional/Mandatory</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Client Id</td>
                            <td>The Id of the
                                merchant issued
                                by CoralPay after
                                profiling.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Client Id</td>
                            <td>The Id of the
                                merchant issued
                                by CoralPay after
                                profiling.</td>
                            <td>Mandatory</td>
                        </tr><tr>
                            <td></td>
                            <td>requestType</td>
                            <td>The type of
                                request the
                                merchant/client is
                                making. Bank
                                Transfer by
                                default.</td>
                            <td>Mandatory</td>
                        </tr><tr>
                            <td></td>
                            <td>Customer Name</td>
                            <td>The desired name
                                the merchant
                                wants to display
                                to the payer at
                                the time of
                                transfer</td>
                        </tr><tr>
                            <td></td>
                            <td>Reference Number</td>
                            <td>A unique
                                transaction
                                reference number</td>
                            <td>Mandatory</td>
                        </tr><tr>
                            <td></td>
                            <td>Transaction Amount</td>
                            <td>The Amount of
                                money the
                                customer is trying
                                to pay.</td>
                            <td>Optional</td>
                        </tr><tr>
                            <td></td>
                            <td>Response Code</td>
                            <td>The final response
                                code of the
                                operation.</td>
                            <td>Mandatory</td>
                        </tr><tr>
                            <td></td>
                            <td>Response Message</td>
                            <td>The final
                                Response
                                Message of the
                                operation</td>
                            <td>Mandatory</td>
                        </tr><tr>
                            <td></td>
                            <td>Account Name</td>
                            <td>The name of the
                                Dynamic Account</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Account Number</td>
                            <td>The created
                                Dynamic Account
                                Number for this
                                request. NOTE:
                                This account is
                                deactivated after
                                a fixed interval of
                                time or after a
                                transaction has
                                been done on the
                                account.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Operation Reference</td>
                            <td>The unique
                                reference of the
                                transaction on
                                CoralPay’s system.</td>
                            <td>Mandatory</td>
                        </tr>
                    </table>
                    <br />

                    <h3>Transaction Payment Notification</h3>
                    <p>This operation is for merchants to receive instant payment notification from CoralPay. This
                        enables the merchant to be aware of payments made to the created accounts. This service is
                        only applicable to the Dynamic Account Numbers. Merchants are to provide this endpoint
                        which is to be maintained on the CoralPay system. The merchant is to provide the Basic
                        Username and Password for Authentication.</p>

                    <p>Below is the Specification for the API to submit the customer’s details.</p>
                    <br />
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Operation</td>
                            <td>Transaction Payment Notification</td>
                        </tr>
                        <tr>
                            <td>Endpoint</td>
                            <td>To be provided by merchant</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>Post</td>
                        </tr>
                        <tr>
                        <td>Content Type</td>
                        <td>Json</td>
                    </tr><tr>
                        <td>Authentication</td>
                        <td>Basic Username : Password</td>
                    </tr><tr>
                        <td>Request Payload</td>
                        <td>{`{`} <br />
                            {` "responseDetails": `} {`{`} <br />
                            {`"clientId": "a@a.com",`} <br />
                            {`"referenceNumber": "xsd4pe0t008952",`}<br />
                            {`"responseCode": "00", `}<br />
                            {`"responseMessage": "OPERATION SUCCESSFUL" `}<br />
                            {`},`} <br />
                            {`"accountName": "MERCHANT(Shehu Chidi & Sons)",`} <br />
                            {`"accountNumber": "9977573777", `}<br />
                            {`"operationReference": "merchant2201250331078455", `}<br />
                            {`"transactionAmount": "6754" `}<br />
                            {`}`}</td>
                    </tr><tr>
                        <td>Request Response</td>
                        <td>{`{
                            "responseMessage": "SUCCESS",
                            "responseCode": "OO"
                        }`}</td>
                    </tr>
                    </table>

                    <br />

                    <h3>Transaction Query</h3>
                    <p>This operation is for merchants to query/check the status of a transaction. This enables the
                        merchant to be aware of payments made to the created accounts. This service is applicable
                        to both Dynamic and Static/Fixed Account Numbers.</p>

                    <p>Below is the Specification for the API to submit the customer’s details.</p>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Operation</td>
                            <td>Transaction Query</td>
                        </tr>
                        <tr>
                            <td>Endpoint</td>
                            <td>/moneytransfer/apis/getTransactionDetails</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>Post</td>
                        </tr>
                        <tr>
                        <td>Content Type</td>
                        <td>Json</td>
                    </tr>
                    <tr>
                        <td>Authentication</td>
                        <td> Basic Username : Password</td>
                    </tr>
                    <tr>
                        <td>Request Payload</td>
                        <td>{`{`} <br />
                            {` "requestHeader": `} {`{`} <br />
                            {`"clientId": "a@a.com",`} <br />
                            {`"requestType": "Money Transfer",`}<br />
                            {`}`}<br />
                            {`"referenceNumber": "A8IQXORB97S0396C94" `}<br />
                            {`},`} <br />
                           </td>
                    </tr>
                    <tr>
                        <td>Response Payload</td>
                        <td>{`{`} <br />
                            {` "responseDetails": `} {`{`} <br />
                            {`"responseCode": "00",`} <br />
                            {`"responseMessage": "TRANSACTION SUCCESSFULL",`}<br />
                            {`}`}<br />
                            {`"nipSessionID": "00000421030118053456480050692666364423457859508199976", `}<br />
                            {`"tranDateTime": "2021-03-01 18:06:20.000",`}<br />
                            {`"transactionAmount": "100",`}<br />
                            {`"transactionType":"BANK TRANSFER",`}<br />
                            {`"transactionSource":"2093566866",`}<br />
                            {`"sourceAccountName":"CASAFINA CREDIT-EASY LOAN",`}<br />
                            {`"sourceBank":"UNITED BANK FOR AFRICA",`}<br />
                            {`},`} <br />
                           </td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td> Field</td>
                        <td> Description</td>
                        <td> Optional/Mandatory</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> Client Id</td>
                        <td> The Id of the merchant issued
                            by CoralPay after profiling.</td>
                        <td> Mandatory</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> requestType</td>
                        <td> The Id of the merchant issued
                            by CoralPay after profiling.</td>
                        <td> Mandatory</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> Reference Number</td>
                        <td> The transaction’s unique
                            reference number</td>
                        <td> Mandatory</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> Response Code</td>
                        <td> The final response code of the
                            operation.</td>
                        <td> Mandatory</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> Response Message</td>
                        <td> The final Response Message of
                            the operation</td>
                        <td> Mandatory</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> NipSessionID</td>
                        <td> The session id for this
                            transaction on NIP</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> TranDateTime</td>
                        <td> The Date and Time of this
                            transaction</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> TransactionAmount</td>
                        <td> The actual amount that was
                            paid into this account</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> TransactionSource</td>
                        <td> The Source Account Number</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> SourceAccountName</td>
                        <td> The Source Account Name</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td> SourceBank</td>
                        <td> The Source Bank</td>
                        <td></td>
                    </tr>
                </table>




                </div>
            </div>

        </div>
    )
}

export default  PaywithBankTransferDocumentation