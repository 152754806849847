import React from "react";
import payload from "../devlopersDocumentations";

const Card3 = () => {
    const card3component = payload.map((i, index) => {
        return(
            <div>
                <div className="card3">
                    <div className="card-img3"></div>
                    <div style={{width:'100%',display: 'flex',alignItems:'center',height:'100%',flexDirection:'column',justifyContent:'space-evenly'}}>
                        <div className="">
                            <img src={i.topIcon} alt='back' />
                            <h3 className="card-3-h3">{i.title}</h3><br />
                            <p>{i.content}</p>
                        </div>
                        <div style={{display:'flex',width:'100%',justifyContent:'flex-start'}}>
                            <img  src={i.bottomIcon} alt='back' />
                            <a href={i.link}> <span className="ml-3" style={{color:'#733158'}}>{i.footerContent}</span></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return(
        [card3component]
    )

}

export default Card3