import React from "react"
const CoralpayVasDocumentation = () => {
    return(
        <div>
            <div className="developer-wrapper">
                <div className="developer-wrapper-overlay">
                    <div className="developer-wrapper-overlay-inner">
                        <h3>C’VAS Interface API Integration</h3>
                        <p className="mt-2 small">This document is an integration document of the CoralPay VAS API Interface.</p>
                        <br /><br />
                        {/*<div style={{display:'flex'}} >*/}
                        {/*    <Button message="API Dashboard" bgColor="#7B3F63" bgText="#FFFFFF" />*/}
                        {/*    <Button  message="Download Document" bgColor="#EFEFEF" bgText="#3A3A3A"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="doc-div-parent-doc">
                <div className="docNav">
                    <h3>Documentation</h3>
                    <ul>
                        <a href={'/developer/docs/cgateussd'}> <li>C’Gate USSD</li></a>
                        <a href={'/developer/docs/pay-with-bank-transfer'}> <li>Pay With Bank Transfer</li></a>
                        <a href={'/developer/docs/payment-gateway'}> <li>CoralPay Payment Gateway</li></a>
                        <a href={'/developer/docs/vas'}> <li>CoralPay VAS</li></a>
                        <a href={'/developer/docs/card-api'}> <li>CoralPay Card APIs</li></a>
                    </ul>
                </div>

                <div className="doc-div-doc">
                    <div style={{display:'flex',width:"100%",justifyContent:'center'}}>
                     <div className="notice-board">
                        <p>Kindly note that the base endpoint URL used in this document is for
                            development and integration testing purposes only and it can be changed
                            without prior notice.</p>

                        <p>The production URL will be communicated when the integration is

                            complete.</p>
                    </div>
                    </div>
                    <h3>PURPOSE OF THIS DOCUMENT</h3>
                    <p>
                        It provides guidance and
                        template material which is intended to assist the relevant stakeholders to easily integrate to the VAS
                        Interface for the vending of value to customers on the different billers, customer account lookup,retrieving vended transaction details, processing vending, etc. The CoralPay VAS interface uses JSON
                        over HTTPS to process incoming requests, sample requests are provided further in this document..
                    </p>

                    <h3>INTEGRATION OVERVIEW</h3>
                    <p>The CoralPay VAS is a secure interface for vending value for different Billers and has the capabilities to
                        do the following:</p>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>BILLER GROUPS
                                ENQUIRY</td>
                            <td>This feature is used to lookup the available active biller groups on the VAS
                                interface. It can also be used to query details for a specific group.</td>
                        </tr>
                        <tr>
                            <td>BILLERS ENQUIRY</td>
                            <td>This feature is used to lookup the available active billers on the VAS
                                interface. It can also be used to query details for a specific biller.</td>
                        </tr>
                        <tr>
                            <td>PACKAGES
                                ENQUIRY</td>
                            <td>This feature is used to lookup the available active packages on the VAS
                                interface. It can also be used to query details for a specific package.</td>
                        </tr>
                        <tr>
                            <td>CUSTOMER
                                ENQUIRY</td>
                            <td>This feature is used to lookup a customer on a specific biller via the VAS
                                interface. It checks whether a customer exists and also returns the
                                payment information for the customer.</td>
                        </tr>
                        <tr>
                            <td>VEND VALUE</td>
                            <td>This is used to push value to the customer’s account. The customer’s
                                account could be a meter number (for Discos), a smartcard number (for
                                paidTV), a phone number (for Telcos), etc.</td>
                        </tr>
                        <tr>
                            <td>PAYMENT
                                VERIFICATION</td>
                            <td>This is used to verify the details for the payment that was made for the
                                vend transaction on the payment gateway.</td>
                        </tr>
                        <tr>
                            <td>VEND
                                NOTIFICATION</td>
                            <td>This is used to post the status of the vending alongside the token
                                purchased (if available) to the notification URL that the institution advices
                                us with.</td>
                        </tr>
                        <tr>
                            <td>VEND
                                TRANSACTION
                                ENQUIRY</td>
                            <td>This is used to check the details of the transaction for the vended value.</td>
                        </tr>
                    </table>
                    <br />
                    <h3>BILLER FEATURES ON API</h3>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Available Billers</td>
                            <td style={{textAlign:'center'}}>API Features</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>CUSTOMER ENQUIRY</td>
                            <td>VEND VALUE</td>
                            <td>VEND TRANSACTION ENQUIRY</td>
                        </tr>
                        <tr>
                            <td>Airtime (MTN, Airtel, Glo, 9Mobile)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Data (MTN, Airtel, Glo, 9Mobile)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>DSTV</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>GOTV</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>StarTimes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Lekki Concession Company Limited (LCC)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Bet9JA</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Churches (RCCG, Living Faith, etc.)</td>
                            <td style={{color:"#ff0000",textAlign:'center'}}>no</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Eko Disco (EKEDP)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Enugu Disco (EEDC)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Abuja Disco (AEDC)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Ikeja Disco (IKEDC)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Ibadan Disco (IBEDC)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Port Harcourt Disco (PHEDC)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Jos Disco (JEDC)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Kano Disco (KEDCO)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Kaduna Disco (KAEDCO)</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Smile</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                        <tr>
                            <td>Spectranet</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                            <td style={{color:"#026802",textAlign:'center'}}>yes</td>
                        </tr>
                    </table>

                    <br />

                    <h3>BILLER GROUPS ENQUIRY</h3>
                    <p>The Biller Group Enquiry endpoint is used to look up biller groups/categories on the CoralPay VAS
                        Interface. An API request to this endpoint will return all the available active biller groups/categories
                        on the VAS Interface. Information about a biller Group is needed to forward request to the billers’
                        endpoint.</p>

                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 Customer wants to know the list of supported biller groups/categories on the CoralPay VAS
                            Interface.</li>
                        <li>2.0 Customer is about to make payment for their account on a specific biller(s).</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                            <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                                <li>1.0 An API request can then be made to the billers’ endpoint to retrieve all the billers under the
                                    specific group/category with the ID or Slug.</li>
                            </ul>
                    <br />
                    <span>Main Scenario</span>
                            <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                                <li>1.0 Customer want to see a list of available biller groups/categories on the CoralPay VAS
                                    Interface..</li>
                                <li>2.0 The platform makes an API request to this endpoint to lookup the available active biller
                                    groups/categories.</li>
                            </ul>

                    <p style={{textAlign:'center'}}>Biller Groups Enquiry Request to the CoralPay VAS Interface</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>URL</td>
                            <td>http://204.8.207.124:8080/coralpay-vas/api/biller-groups</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>GET</td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>text/plain</td>
                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>Not Applicable.</td>
                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>Not Applicable</td>
                        </tr>
                    </table>
                    <br />
                    <p style={{textAlign:'center'}}>Biller Groups Enquiry Response to the CoralPay VAS Interface</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>Response JSON Object</td>
                            <td>{`{`}<br />
                                {`"error": false,`}<br />
                                {`"status": "success",`}<br />
                                {`"message": "Successfully fetched biller groups",`}<br />
                                {`"responseCode": "00",`}<br />
                                {`"responseData": [`}<br />
                                           {` {`}<br />
                                                {`"id": 1,`}<br />
                                               {` "name": "Paid TV",`}<br />
                                               {` "slug": "PAID_TV"`}<br />
                                           {` },`}<br />
                                           {` {`}<br />
                                               {` "id": 2,`}<br />
                                                {`"name": "Discos",`}<br />
                                              {`  "slug": "DISCOS"`}<br />
                                           {` },`}<br />
                                               {` . . .`}<br />
                               {` ]`}<br />
                           {` }`}</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                        </tr>
                        <tr>
                            <td>error</td>
                            <td>This states whether an error occurred.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>This is the status of the request</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>message</td>
                            <td>The description of the response</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of the request</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseData</td>
                            <td>This holds any data that is returned for
                                the request. In this case, it holds an
                                array of all the biller groups/categories
                                on the CoralPay VAS Interface.</td>
                            <td>Mandatory</td>
                        </tr>
                    </table>

                    <br />
                    <h3>BILLERS ENQUIRY</h3>
                    <p>The Billers Enquiry endpoint is used to look up billers on the CoralPay VAS Interface. An API request to
                        this endpoint will return all the available active billers under the passed in ID or Slug for the biller
                        group/category passed. Information about a biller is needed to forward request to the Customer
                        Enquiry and Packages Enquiry endpoints.</p>
                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 Customer wants to know the list of supported billers on the CoralPay VAS Interface.</li>
                        <li>2.0 Customer is about to make payment for their account on a specific biller(s).</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 An API request can then be made to the billers’ endpoint to retrieve all the billers under the
                            specific group/category with the ID or Slug.</li>
                    </ul>
                    <br />
                    <span>Main Scenario</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 Customer want to see a list of available biller groups/categories on the CoralPay VAS
                            Interface..</li>
                        <li>2.0 The platform makes an API request to this endpoint to lookup the available active biller
                            groups/categories.</li>
                    </ul>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Request with Biller Group ID</td>
                        </tr>
                        <tr>
                            <td>URL</td>
                            <td>http://204.8.207.124:8080/coralpay-
                                vas/api/billers/group/{`{billerGroupID}`}</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>GET</td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>text/plain</td>
                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>Not Applicable.</td>
                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>Not Applicable</td>
                        </tr>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                        </tr>
                        <tr>
                            <td>billerGroupSlug</td>
                            <td>The slug identifier for the biller
                                group/category to retrieve all the
                                active available billers for.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>

                    </table>
                    <p style={{textAlign:'center'}}>Billers Enquiry Response to the CoralPay VAS Interface</p>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>Response JSON Object</td>
                            <td>{`{`}<br />
                                {`"error": false,`}<br />
                                {`"status": "success",`}<br />
                                {`"message": "Successfully fetched billers",,`}<br />
                                {`"responseCode": "00",`}<br />
                                {`"responseData": [`}<br />
                                {` {`}<br />
                                {`"id": 1,`}<br />
                                {` "name": "DSTV",`}<br />
                                {` "slug": "DSTV",`}<br />
                                {` "groupId": 1`}<br />
                                {` },`}<br />
                                {` . . .`}<br />
                                {` ]`}<br />
                                {` }`}</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                        </tr>
                        <tr>
                            <td>error</td>
                            <td>This states whether an error occurred.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>This is the status of the request</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>message</td>
                            <td>The description of the response</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of the request</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseData</td>
                            <td>This holds any data that is returned for
                                the request. In this case, it holds an
                                array of all the biller groups/categories
                                on the CoralPay VAS Interface.</td>
                            <td>Mandatory</td>
                        </tr>
                    </table>
                    <br />
                    <h3>PACKAGES ENQUIRY</h3>
                    <p>The Packages Enquiry endpoint is used to look up packages on the CoralPay VAS Interface. An API
                        request to this endpoint will return all the available active packages under the passed in ID or Slug for
                        the biller. Information about a biller’s package is needed to forward request to the Vend Value
                        endpoint.</p>

                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 Customer wants to know the list of supported biller groups/categories on the CoralPay VAS
                            Interface.</li>
                        <li>2.0 Customer is about to make payment for their account on a specific biller(s).</li>
                        <li>3.0 The biller ID or Slug has already been obtained from the Billers Enquiry API.</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 An API request can then be made to the billers’ endpoint to retrieve all the billers under the
                            specific group/category with the ID or Slug.</li>
                    </ul>
                    <br />
                    <span>Main Scenario</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 Customer want to see a list of available packages for the selected biller on the CoralPay VAS
                            Interface.</li>
                        <li>2.0 The platform makes an API request to this endpoint to lookup the available active packages
                            under the selected biller..</li>
                    </ul>

                    <p>Packages Enquiry Response to the CoralPay VAS Interface</p>


                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Request with Biller ID</td>
                        </tr>
                        <tr>
                            <td>URL</td>
                            <td>http://204.8.207.124:8080/coralpay-vas/api/packages/biller/{`{billerID}`}</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>GET</td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>text/plain</td>
                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>Not Applicable.</td>
                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>Not Applicable</td>
                        </tr>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                        </tr>
                        <tr>
                            <td>billerGroupSlug</td>
                            <td>The slug identifier for the biller
                                group/category to retrieve all the
                                active available billers for.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                    </table>
                    <br />
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Request with Slug</td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>URL</td>
                            <td>http://204.8.207.124:8080/coralpay-vas/api/packages/biller/slug/{`{billerSlug}`}</td>
                        </tr>
                        <tr>
                            <td>Response JSON Object</td>
                            <td>{`{`}<br />
                                {`"error": false,`}<br />
                                {`"status": "success",`}<br />
                                {`"message": "Successfully fetched packages",`}<br />
                                {`"responseCode": "00",`}<br />
                                {`"responseData": [`}<br />
                                {` {`}<br />
                                {`"id": 1,`}<br />
                                {` "name": "DSTV",`}<br />
                                {` "slug": "DSTV",`}<br />
                                {` "groupId": 1`}<br />
                                {` },`}<br />
                                {` . . .`}<br />
                                {` ]`}<br />
                                {` }`}</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>
                        </tr>
                    </table>

                    <br />

                    <h3>CUSTOMER ENQUIRY</h3>
                    <p>The Customer Enquiry endpoint MUST be called first in the transaction process. The response will
                        include all the required information to initiate a direct vend, product upgrade / addon, etc. Important
                        information and points to note on the Lookup response</p>

                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 Customer has provided their CustomerId (could be their meter number, smartcard number,
                            etc.) or OrderId..</li>
                        <li>2.0 Customer is about to make payment for their account on the VAS service..</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 An API request can then be made to vend the value for the validated/verified customer..</li>
                    </ul>
                    <br />
                    <span>Main Scenario</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 Customer chooses to PAY for their bills via USSD on the POS, mobile banking, Website, etc.</li>
                        <li>2.0 Customer enters their meter number, phone number, smartcard number, etc.</li>
                        <li>3.0 The platform makes an API request to this endpoint to lookup the customer’s account and
                            returns details for the customer and any outstanding amount to be paid by the customer.</li>
                    </ul>

                    <p>Customer Lookup Request to the CoralPay VAS Interface</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>URL</td>
                            <td>http://204.8.207.124:8080/coralpay-vas/api/transactions/customer-lookup</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>GET</td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>text/plain</td>
                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>{`{ "customerId": "0101160313181", "billerSlug": "EKO_DISCO",}`}</td>
                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>JSON Object</td>
                        </tr>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                        </tr>
                        <tr>
                            <td>customerId</td>
                            <td>The identifier for the customer’s
                                account on the biller. It could be
                                meter number, phone number,
                                smartcard number, account
                                number, etc.</td>
                            <td>customerId is Mandatory
                                when carrying out normal bill
                                payments which does not
                                require an Order ID.</td>
                        </tr>
                        <tr>
                            <td>orderId</td>
                            <td>The orderId that the customer has
                                obtained from the Biller and
                                wishes to pay. This is available for
                                some billers.</td>
                            <td>This is Mandatory when
                                paying for an Order</td>
                        </tr>
                        <tr>
                            <td>billerId</td>
                            <td>The ID for the biller on the
                                CoralPay VAS Interface. Call the get
                                billers endpoint to see a list of all
                                currently available billers.</td>
                            <td>The billerId or the billerSlug
                                is Mandatory and must be
                                present in the request.</td>
                        </tr>
                        <tr>
                            <td>billerSlug</td>
                            <td>The Slug for the biller on the
                                CoralPay VAS Interface. Call the get
                                billers endpoint to see a list of all
                                currently available billers.</td>
                            <td>The billerId or the billerSlug
                                is Mandatory and must be
                                present in the request.</td>
                        </tr>
                        <tr>
                            <td>productName</td>
                            <td>The name of the product/plan to
                                enquiry about for the customer.
                                The product name is typically the
                                package Slug.</td>
                            <td>It is required for some billers.
                                Billers which require this
                                include:
                                - all the Discos</td>
                        </tr>
                    </table>

                    <p>Customer Lookup Response to the CoralPay VAS Interface</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>Response JSON Object</td>
                            <td>{`{`}<br />
                                {`"error": false,`}<br />
                                {`"status": "success",`}<br />
                                {`"message": "Successfully fetched customer account details",`}<br />
                                {`"responseCode": "00",`}<br />
                                {`"responseData": {`}<br />
                                {` "customer": {`}<br />
                                {`"firstName": "Test Customer",`}<br />
                                {` "lastName": "Example",`}<br />
                                {` "totalDue": "6800",`}<br />
                                {` "dueDate": "2018-09-07T00:00:00",`}<br />
                                {` "products": [],`}<br />
                                {` },`}<br />
                                {` "orderId": "136413641",`}<br />
                                {` "minAmount": 1250.50,`}<br />
                                {` "customerMessage": "Hello Tester, you are currently
                                    paying for DSTV Box Office via GTBank. Kindly note that
                                    the minimum amount you can pay is NGN1250.50. Thank
                                    you",`}<br />
                                {` }`}<br />
                                {` }`}</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                        </tr>
                        <tr>
                            <td>error</td>
                            <td>This states whether an error occurred.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>This is the status of the request</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>message</td>
                            <td>The description of the response</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of the request</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseData</td>
                            <td>This holds any data that is returned for
                                the request. In this case, it holds an
                                array of all the biller groups/categories
                                on the CoralPay VAS Interface.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>customer</td>
                            <td>This holds any data that is returned for
                                the request. In this case, it holds the
                                customer lookup data.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>products</td>
                            <td>This is an array of all the products that
                                this customer has currently subscribed
                                on their account (for billers that allow
                                multiple products on a customer’s
                                account). It is within the returned
                                customer data.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>orderId</td>
                            <td>The orderId for the customer’s current
                                transaction on the VAS provider (some
                                billers support this). If this is returned, it
                                must be passed as part of the request to
                                vend value for the customer.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>minAmount</td>
                            <td>This is the min amount the customer
                                should pay for this transaction. This is
                                required by some billers.</td>
                            <td>Mandatory for
                                some billers</td>
                        </tr>
                        <tr>
                            <td>customerMessage</td>
                            <td>This is the message that should be
                                displayed to the customer during
                                payment.</td>
                            <td>Optional</td>
                        </tr>
                    </table>
                    <br />
                    <h3>VEND VALUE</h3>
                    <p>The Vend Value endpoint is called to vend the actual token/value to the customer. The response will
                        include a status of the vend request and the vended token (available for some billers). You must pass
                        a paymentReference to this endpoint that is verifiable on the payment gateway used to process the
                        payment transaction to ensure the customer’s account was debited with the actual amount for the
                        value being vended for.</p>

                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The Customer Enquiry endpoint has been called to obtain the amount due to the customer or
                            an OrderId (available on some billers).</li>
                        <li>2.0 The customer’s bank account has been successfully debited with the total due amount/the
                            amount of the package being paid for via the payment gateway and a paymentReference has
                            been returned by the payment gateway.</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The customer’s account is credited with the value amount or the API response will contain a
                            token which represents the value amount vended (for some billers).</li>
                    </ul>
                    <br />
                    <span>Main Scenario</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The platform makes an API request to this endpoint with the paymentReference obtained
                            from the payment gateway and the identifier of the package paid for by the customer.</li>
                    </ul>

                    <p>Vend Value Request to the CoralPay VAS Interface</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>URL</td>
                            <td>{`http://204.8.207.124:8080/coralpay-vas/api/transactions/process-payment`}</td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>POST</td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/Json</td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>{`{ "paymentReference": "5352405452351", "customerId":
                                    "0101160313181", "packageSlug": "OFFLINE_PREPAID", "channel":
                                    "WEB", "amount": 6800.50, "customerName": "Tester Example",
                                    "phoneNumber": "23480812345", "email":
                                    "tester.example@sample.com", "accountNumber": "0012345678" }`}</td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>JSON Object</td>
                            <td></td>

                        </tr>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>

                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the unique payment
                                reference obtained from the
                                payment gateway. This is required
                                to process the vend value request.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>customerId</td>
                            <td>The identifier for the customer’s
                                account on the biller. It could be
                                meter number, phone number,
                                smartcard number, account
                                number, etc..</td>
                            <td>customerId is Mandatory
                                when carrying out normal bill
                                payments which does not
                                require an Order ID.</td>
                        </tr>
                        <tr>
                            <td>orderId</td>
                            <td>The orderId that the customer has
                                obtained from the Biller and
                                wishes to pay. This is available for
                                some billers.</td>
                            <td>This is Mandatory when
                                paying for an Order or when
                                an orderId is returned from
                                the Customer Enquiry
                                endpoint.</td>
                        </tr>
                        <tr>
                            <td>packageId</td>
                            <td>The ID for the package on the
                                CoralPay VAS Interface. Call the
                                package enquiry endpoint to see a
                                list of all currently available
                                packages under the selected biller.</td>
                            <td>The packageId or the
                                packageSlug is Mandatory
                                and must be present in the
                                request.</td>
                        </tr>
                        <tr>
                            <td>packageSlug</td>
                            <td>The Slug for the package on the
                                CoralPay VAS Interface. Call the
                                package enquiry endpoint to see a
                                list of all currently available
                                packages under the selected biller.</td>
                            <td>The packageId or the
                                packageSlug is Mandatory
                                and must be present in the
                                request.</td>
                        </tr>
                        <tr>
                            <td>channel</td>
                            <td>This is the channel for the
                                payment. This is required to
                                process the vend value request.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>The total amount the customer is
                                paying. <br /><br /><span style={{color:'red'}}>Not required for DSTV and GOTV
                                billers since they have fixed
                                amount for their
                                    packages/bouquets.</span></td>
                            <td>Mandatory<br /><br /><span style={{color:'red'}}>Not required for DSTV and GOTV
                                billers since they have fixed
                                amount for their
                                    packages/bouquets.</span></td>
                        </tr>
                        <tr>
                            <td>customerName</td>
                            <td>The full name of the customer.</td>
                            <td>Optional<br /><br /><span style={{color:'red'}}>Maximum length of 300 characters.</span></td>
                        </tr>
                        <tr>
                            <td>phoneNumber</td>
                            <td>The phone number of the
                                customer.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>email</td>
                            <td>The email address of the customer.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>accountNumber</td>
                            <td>The account number of the
                                customer.</td>
                            <td>Optional</td>
                        </tr>
                    </table>
                    <br/>
                    <p>Vend Value Response to the CoralPay VAS Interface</p>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>Response JSON Object</td>
                            <td>{`{`}<br />
                                {`"error": false,`}<br />
                                {`"status": "success",`}<br />
                                {`"message": "Successfully fetched packages",`}<br />
                                {`"responseCode": "00",`}<br />
                                {`"responseData": `}
                                {` {`}<br />
                                {`"packageName": "OFFLINE Prepaid",`}<br />
                                {` "paymentReference": "5352405452351",`}<br />
                                {` "transactionId": "1A20FD0343CB6FE5C7",`}<br />
                                {` "vendStatus": "CONFIRMED",`}<br />
                                {` "narration": "Payment for my bills",`}<br />
                                {` "statusCode": 00`}<br />
                                {` "amount": 6800.50,`}<br />
                                {` "date": "2018-09-07T00:00:00",`}<br />
                                {` "token": {,`}<br />
                                {` "stdToken": {
                                        "amount": "6800",
                                        "units": "12500.79",
                                        },`}<br />
                                {`"daysLastVend": "2018-09-07T00:00:00",`}
                                {` . . .`}<br />
                                {` },`}<br />
                                {` }`}</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Field Description</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>error</td>
                            <td>This states whether an error occurred.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>This is the status of the request</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>message</td>
                            <td>The description of the response.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of the
                                transaction. The codes are based on
                                ISO8583 financial codes. You can find a
                                comprehensive list in the Appendix
                                section below.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseData</td>
                            <td>This holds any data that is returned for
                                the request. In this case, it holds the
                                vend value response data.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>packageName</td>
                            <td>This is the name of the package that was
                                vended.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the payment reference that was
                                sent by the client/partner to this
                                endpoint. It must be the same as what
                                was sent to this endpoint.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>transactionId</td>
                            <td>This is the ID we assigned to this
                                transaction. You can look up the details
                                of a transaction on the CoralPay VAS
                                Interface with this ID.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>vendStatus</td>
                            <td>The status of the vend.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>narration</td>
                            <td>The description of this transaction that
                                was returned by the biller..</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>statusCode</td>
                            <td>The status code of the vend. The codes
                                are based on ISO8583 financial codes.
                                You can find a comprehensive list in the
                                Appendix section below..</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>The total amount value that was vended
                                to the customer.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>date</td>
                            <td>The date the value was vended to the
                                customer (typically the current date but
                                sometimes value might be vended at a
                                later time/date).</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>tokenData</td>
                            <td>This contains the data of the vended
                                token which represents the value the
                                customer paid for..</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                    </table>
                    <br />

                    <h3>PAYMENT VERIFICATION FEATURE</h3>

                    <p>The CoralPay VAS Interface supports two modes of configuration for institutions with regards to how
                          transactions should be handled. The two mode are:</p>

                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li><b>PrePaid Mode:</b> Institution will hold a prepaid position with CoralPay where every successful
                            VEND VALUE transaction depletes the balance of that prepaid position with the value vended.</li>
                        <li><b>PostPaid Mode:</b> Institution does not need to hold a prepaid position with CoralPay. In this
                            scenario, the institution will provide CoralPay with a Payment verification URL where the
                            CoralPay VAS Interface can check to confirm payment transactions before releasing value to
                            the customer.</li>
                    </ul >

                    <p>In the case where the institution is configured in scenario two, the following will hold true:</p>

                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The platform makes an API request to the VEND VALUE endpoint with the <span style={{color:'red'}}>paymentReference</span> obtained from the payment gateway for Cgate VAS Interface to give value to the customer.</li>
                        <li>2.0 The institution has provided CoralPay with its payment verification URL and this has been
                            properly profiled on the Cgate VAS Interface.</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The vend value transaction is allowed to continue if the payment is verified, if not the vend
                            transaction is truncated and an error message is returned.</li>
                    </ul>
                    <br />
                    <span>Main Scenario</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 CoralPay VAS Interface will make a request to the institution’s payment verification URL to
                            verify that the payment occurred on the payment gateway.</li>
                    </ul>

                    <p>Payment Verification Request to the Institution from CoralPay VAS Interface</p>
                    <br />
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>URL</td>
                            <td>TO BE PROVIDED BY INSTITUTION</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>
                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>{`{ "paymentReference": "5352405452351" }`}</td>
                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>JSON Object</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Field Description</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the payment reference
                                obtained from the payment
                                gateway. This is sent to the
                                institution’s payment verification
                                URL to retrieve details from the
                                payment gateway for the
                                transaction.</td>
                            <td>Mandatory</td>
                        </tr>
                    </table>
                    <br />
                     <p>Payment Verification Response from the Institution to the CoralPay VAS Interface</p>
                    <br />
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json.</td>
                        </tr>
                        <tr>
                            <td>Response JSON Object</td>
                            <td>{`{ "paymentReference": "5352405452351",
                                "customerName": "Sample User", "institutionId": 1,
                                "channel": "WEB", "amountPaid": 6800.75, "narration":
                                "5352405451//Payment for my DSTV premium//2019-03-
                                15T17:38:50.234Z", "transactionDate": "2019-03-
                                15T17:38:50.234Z", "responseCode": "00",
                                "responseMessage": "Successfully fetched details for the
                                payment reference" }.`}</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object.</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Field Description</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of the
                                transaction. The codes MUST be based
                                on ISO8583 financial codes. You can find
                                a comprehensive list in the Appendix
                                section below.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseMessage</td>
                            <td>The description of the response</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the payment reference that was
                                sent by CoralPay VAS Interface.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>channel</td>
                            <td>This is the channel for the payment..</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>amountPaid</td>
                            <td>The total amount paid by the customer
                                on the payment gateway for that
                                payment reference.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>narration</td>
                            <td>The description of this transaction that
                                was returned by the biller.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>customerName</td>
                            <td>The full name of the customer that paid
                                for the payment reference.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>institutionId</td>
                            <td>This is the ID string we assigned to your
                                institution. <span style={{color:'red'}}>This will be provided to you
                                during integration testing (for the test
                                APIs) and when going LIVE (for the
                                    production APIs)</span>.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>transactionDate</td>
                            <td>The date that the payment was made.
                                <span style={{color:'red'}}>The date value should be in standard
                                    ISO date format</span>.</td>
                            <td>Mandatory</td>
                        </tr>
                    </table>
                    <br />

                    <h3>VEND NOTIFICATION FEATURE</h3>

                    <p>The Vend Notification Feature is used by institutions to receive a notification for every Confirmed or
                        Failed transaction vended via their institution. The received notification response will include the full
                        details of the vend transaction including the vended token (available for some billers).</p>

                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The Institution wishes to obtain details about the vend transaction as a notification pushed to
                            their preferred notification URL.</li>
                        <li>2.0 The Institution has advised CoralPay of their notification URL and the same has been correctly
                            profiled on their account on the CoralPay VAS Interface.</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The details for the vend transaction (either Confirmed or Failed) is pushed to the institution’s
                            profiled notification URL.</li>
                    </ul>
                    <br />
                    <span>Main Scenario</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 A vend transaction was made on the CoralPay VAS Interface via the VEND VALUE Endpoint
                            using a valid paymentReference.</li>
                    </ul>

                    <p>Vend Notification Push to the Institution from CoralPay VAS Interface</p>
                    <br />

                    <table style={{width:'910px'}}>
                        <tr>
                            <td>URL</td>
                            <td>TO BE PROVIDED BY INSTITUTION</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>POST</td>

                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/Json</td>

                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>{`{ "error": false,
                                    "status": "success",
                                    "message": "Token was successfully vended",
                                    "responseCode": "00",
                                    "responseData": {
                                    "billerName": "EKO Disco",
                                    "paymentReference": "5352405452351",
                                    "transactionId": "1A20FD0343CB6FE5C7",
                                    "vendStatus": "CONFIRMED",
                                    "narration": "Payment for my bills",
                                    "statusCode": "00",
                                    "amount": 6800,
                                    "date": "2018-09-07T00:00:00",
                                    "tokenData": {
                                    "stdToken": {
                                    "amount": "6800",
                                    "units": "12500.79",
                                    },
                                    "daysLastVend": "2018-09-07T00:00:00",
                                    . . .
                                    }
                                    } }`}</td>

                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>JSON Object</td>

                        </tr>
                    </table>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the unique payment
                                reference obtained from the
                                payment gateway. This is required
                                to process the vend value request.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>customerId</td>
                            <td>The identifier for the customer’s
                                account on the biller. It could be
                                meter number, phone number,
                                smartcard number, account
                                number, etc..</td>
                            <td>customerId is Mandatory
                                when carrying out normal bill
                                payments which does not
                                require an Order ID.</td>
                        </tr>
                        <tr>
                            <td>orderId</td>
                            <td>The orderId that the customer has
                                obtained from the Biller and
                                wishes to pay. This is available for
                                some billers.</td>
                            <td>This is Mandatory when
                                paying for an Order or when
                                an orderId is returned from
                                the Customer Enquiry
                                endpoint.</td>
                        </tr>
                        <tr>
                            <td>error</td>
                            <td>This states whether an error
                                occurred..</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>This is the status of the
                                request</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>message</td>
                            <td>The description of the
                                response</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of
                                the transaction. The codes are
                                based on ISO8583 financial codes. You can find a
                                comprehensive list in the
                                Appendix section below.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseData</td>
                            <td>This holds any data that is
                                returned for the request. In
                                this case, it holds the vend
                                transaction data.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>billerName</td>
                            <td>This is the name of the biller
                                that the package was vended
                                under.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>transactionId</td>
                            <td>This is the id we assigned to
                                this transaction. You can look
                                up the details of a transaction
                                on the CoralPay VAS Interface
                                with this Id.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the payment reference
                                that was sent by the
                                client/partner to this endpoint
                                for the transaction.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>vendStatus</td>
                            <td>The status of the vend.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>narration</td>
                            <td>The description of the
                                transaction that was returned
                                by the biller.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>statusCode</td>
                            <td>The status code of the vend.
                                The codes are based on
                                ISO8583 financial codes. You
                                can find a comprehensive list
                                in the Appendix section below.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>The total amount value that
                                was vended to the customer.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>date</td>
                            <td>The date the value was vended
                                to the customer.</td>
                            <td>Optional</td>
                        </tr>
                        <tr>
                            <td>tokenData</td>
                            <td>This contains the data of the
                                vended token which
                                represents the value the
                                customer paid for.</td>
                            <td>Optional</td>
                        </tr>
                    </table>

                    <br/>
                    <p>Vend Notification Push Response from the Institution to CoralPay VAS Interface</p>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>

                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>{`{
                                    " responseMessage ": "Successfully received the vend
                                    transaction notification",
                                    "responseCode": "00"
                                    }`}</td>

                        </tr>
                    </table>

                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of the vend
                                notification. The codes MUST be based
                                on ISO8583 financial codes. You can find
                                a comprehensive list in the Appendix
                                section below.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>responseMessage</td>
                            <td>The description of the response</td>
                            <td>Optional.</td>
                        </tr>
                    </table>

                    <br />

                    <h3>VEND TRANSACTION ENQUIRY</h3>

                    <p>The Vend Transaction Enquiry endpoint is called to lookup details about a transaction for a vended
                        value identified by its transactionId. The response will include the full details of the vend transaction
                        including the vended token (available for some billers). You must pass the <span style={{color:'red'}}>paymentReference</span> or the
                        <span style={{color:'red'}}> transactionId </span> for the transaction to this endpoint..</p>

                    <span>Pre-Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 A vend transaction was made on the CoralPay VAS Interface via the VEND VALUE Endpoint
                            using a valid paymentReference and a transactionId was returned for the transaction..</li>
                        <li>2.0 The Customer/Platform wishes to obtain details about the vend transaction with the specific
                            paymentReference / transactionId on the CoralPay VAS Interface..</li>
                        <li>3.0 The Customer/Platform has the paymentReference / transactionId for the vend transaction.</li>
                    </ul >
                    <br />
                    <span>Post Condition</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The details for the vend transaction is returned; the API response may contain the token data
                            (for some billers).</li>
                    </ul>
                    <br />
                    <span>Main Scenario</span>
                    <ul style={{listStyle:'circle',marginTop:'5px',marginLeft:'3%',marginBottom:'3%'}}>
                        <li>1.0 The platform makes an API request to this endpoint with the paymentReference or
                            transactionId for a vended token transaction to obtain the details of that transaction.</li>
                    </ul>

                    <p>Vend Transaction Enquiry Request to the CoralPay VAS Interface</p>
                    <br />
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td>URL</td>
                            <td>"http://204.8.207.124:8080/coralpay-vas/api/transactions/payment-
                                lookup/?paymentReference=5352405452351" <br /> OR <br />
                                "http://204.8.207.124:8080/coralpay-vas/api/transactions/payment-
                                lookup/?transactionId=1A20FD0343CB6FE5C7"</td>
                        </tr>
                        <tr>
                            <td>Verb</td>
                            <td>GET</td>
                        </tr>
                        <tr>
                            <td>Content-Type</td>
                            <td>text/plain</td>
                        </tr>
                        <tr>
                            <td>JSON Object</td>
                            <td>Not Applicable</td>
                        </tr>
                        <tr>
                            <td>Request Data</td>
                            <td>Not Applicable</td>
                        </tr>
                    </table>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the unique payment
                                reference obtained from the
                                payment gateway and used to
                                post the vend request.</td>
                            <td>The paymentReference or the
                                transactionId is Mandatory and
                                must be present in the request.</td>
                        </tr>
                        <tr>
                            <td>transactionId</td>
                            <td>This is the ID we assigned to
                                the transaction.</td>
                            <td>The paymentReference or the
                                transactionId is Mandatory and
                                must be present in the request.</td>
                        </tr>
                        <tr>
                            <td>transactionId</td>
                            <td>This is the ID we assigned to
                                the transaction.</td>
                            <td>The paymentReference or the
                                transactionId is Mandatory and
                                must be present in the request.</td>
                        </tr>
                    </table>

                    <br />
                    <p>Vend Transaction Enquiry Response to the CoralPay VAS Interface</p>

                    <br />
                    <table style={{width:'910px'}}>
                        <tr>
                            <td>Content-Type</td>
                            <td>application/json</td>
                        </tr>
                        <tr>
                            <td>Response JSON Object</td>
                            <td>{`{
                                    "error": false,
                                    "status": "success",
                                    "message": "Successfully fetched the transaction details",
                                    "responseCode": "00",
                                    "responseData": {
                                    "billerName": "EKO Disco",
                                    "paymentReference": "5352405452351",
                                    "transactionId": "1A20FD0343CB6FE5C7",
                                    "vendStatus": "CONFIRMED",
                                    "narration": "Payment for my bills",
                                    "statusCode": "00",
                                    "amount": 6800,
                                    "date": "2018-09-07T00:00:00",
                                    "tokenData": {
                                    "stdToken": {
                                    "amount": "6800",
                                    "units": "12500.79",
                                    },
                                    "daysLastVend": "2018-09-07T00:00:00",
                                    . . .
                                    }
                                    }
                                    }}`}</td>
                        </tr>
                        <tr>
                            <td>Response Data</td>
                            <td>JSON Object</td>
                        </tr>
                    </table>
                    <table style={{width:'910px'}}>
                        <tr>
                            <td></td>
                            <th style={{textAlign:'center'}}>Field Description</th>
                            <td></td>
                        </tr>
                        <tr>
                            <td>error</td>
                            <td>This states whether an error occurred.</td>
                            <td>Mandatory</td>
                        </tr>
                        <tr>
                            <td>transactionId</td>
                            <td>This is the ID we assigned to
                                the transaction.</td>
                            <td>The paymentReference or the
                                transactionId is Mandatory and
                                must be present in the request.</td>
                        </tr>
                        <tr>
                            <td>status</td>
                            <td>This is the status of the request</td>
                            <td>Optional.</td>
                        </tr>
                        <tr>
                            <td>message</td>
                            <td>The description of the response</td>
                            <td>Optional.</td>
                        </tr>
                        <tr>
                            <td>responseCode</td>
                            <td>This is the response code of the vend
                                transaction. The codes are based on
                                ISO8583 financial codes. You can find a
                                comprehensive list in the Appendix
                                section below.</td>
                            <td>Mandatory.</td>
                        </tr>
                        <tr>
                            <td>responseData</td>
                            <td>This holds any data that is returned for
                                the request. In this case, it holds the
                                transaction data.</td>
                            <td>Mandatory.</td>
                        </tr>
                        <tr>
                            <td>billerName</td>
                            <td>This is the name of the biller that the
                                package was vended under.</td>
                            <td>Optional.</td>
                        </tr>
                        <tr>
                            <td>paymentReference</td>
                            <td>This is the payment reference that was
                                sent by the client/partner to this
                                endpoint for the transaction..</td>
                            <td>Mandatory.</td>
                        </tr>
                        <tr>
                            <td>transactionId</td>
                            <td>This is the id we assigned to this
                                transaction. You can look up the details
                                of a transaction on the CoralPay VAS
                                Interface with this Id..</td>
                            <td>Mandatory.</td>
                        </tr>
                        <tr>
                            <td>vendStatus</td>
                            <td>The status of the vend..</td>
                            <td>Mandatory.</td>
                        </tr>
                        <tr>
                            <td>narration</td>
                            <td>The description of the transaction that
                                was returned by the biller.</td>
                            <td>Optional.</td>
                        </tr>
                        <tr>
                            <td>statusCode</td>
                            <td>The status code of the vend. The codes
                                are based on ISO8583 financial codes.
                                You can find a comprehensive list in the
                                Appendix section below.</td>
                            <td>Mandatory.</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>The status code of the vend. The codes
                                are based on ISO8583 financial codes.
                                You can find a comprehensive list in the
                                Appendix section below.</td>
                            <td>Mandatory.</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>The total amount value that was vended
                                to the customer..</td>
                            <td>Optional.</td>
                        </tr>
                        <tr>
                            <td>date</td>
                            <td>The date the value was vended to the
                                customer..</td>
                            <td>Optional.</td>
                        </tr>
                        <tr>
                            <td>tokenData</td>
                            <td>This contains the data of the vended
                                token which represents the value the
                                customer paid for.</td>
                            <td>Optional.</td>
                        </tr>
                    </table>


                </div>
            </div>
        </div>
    )
}

export default  CoralpayVasDocumentation