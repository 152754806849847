import React from "react"
import DocumentationLink from "../../components/DocumentationLink";
import paymentNotification from "../../assets/payment-noti4x.png"


const TransactionPaymentNotification = () => {
    return(
        <div className="flex-section">
            <DocumentationLink/>
            <div>
                <div className="doc-content">
                     <h3>Transaction Payment Notification</h3>
                     <p>This operation is for merchants to receive instant payment notification from CoralPay. This
                         enables the merchant to be aware of payments made to the created accounts. This service is
                         only applicable to the Dynamic Account Numbers. Merchants are to provide this endpoint
                         which is to be maintained on the CoralPay system. The merchant is to provide the Basic
                         Username and Password for Authentication. </p>
                    <div className="doc-content-inner">
                           <img src={paymentNotification} alt="responseCode"  className="doc-content-inner-img"/>
                           {/*<div>*/}
                           {/*    <p>Module Value: SHA-512(Account Name + Account Number + Transaction Amount)</p>*/}
                           {/*</div>*/}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TransactionPaymentNotification